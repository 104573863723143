.index-Page{
    background-color: $off-white;
    footer{
        margin-top: 0 !important;
    }
 
.rewards-system{
    position: relative;
    width: 100vw;
    height: 100%;
    background: $off-white;
    overflow: hidden;
    &__header{
        background-color: red;
        height: 40%;
        width: 100%;
        position: relative;
        /* margin-bottom: 11rem; */
        min-height: 23rem;
        &__primary-text{
            @include centerer;
            top: 36%;
            h1{
                font-family: $ultra-font,sans-serif;
                text-transform: uppercase;
                text-align: center;
                color: $white;
                font-size: 56px;
                line-height: 47px;
             
            }
        }
        .down-strips{
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 80px;
            font-size: 10px;
            width: 5000px;
            margin-bottom: 0;
            background-color: $black;
            @media (max-width: 767px) {
                height: 180px;
            }
            .down-strips__strip{
                width:4em;
                height: 100%;
                background-color: $white;
                float: left;
                margin-right: 3.8em;
                opacity: 1;
            }
        }
        &-blocks{
            position: absolute;
            width: 100%;
            height: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 13px;
            bottom: -25%;
            @include breakpoint(smallScreen){
                font-size: 7px;
                bottom: -10%;
            }
            @include breakpoint(largeMobile){
                font-size: 5px;
                bottom: -8%;
            }
            @include breakpoint(mobile){
                font-size: 3px;
                bottom: 3%;
            }
            @media (max-width: 1200px)and (min-width: 1000px) {
                font-size: 7px;
                bottom: -10%;
            }
            .badge-block{
                position: relative;
                width: 20.78em;
                height: 20.78em;
                background-color: #fff;
                border-radius: 6em;
                margin:0 .6rem;
                padding:4em;
                text-align: center;
                box-shadow: 0px 10px 30px rgba(0,0,0,.26);
                img{
                    width: 90%;
                    height: 90%;
                    object-fit: contain;
                }
            }

        }
    }


    .section_two{
        font-size: 10px;
        position: relative;
        padding: 2rem;
        // margin-top: 10rem;
        height: 60%;
        @include breakpoint(smallScreen){
            min-height: 45rem;
        }
        @media (max-width: 900px)and (min-width: 765px) {
            min-height: 32rem;
        }
        @media (max-width: 767px)and (min-width: 767px) {
            min-height: 47rem;
        }
        .strips-container{
            position: absolute;
            width: 60%;
            height: 100%;
            left: 0;
            top: 0;
            left: -6rem;
            // @include breakpoint(mdScreen){
            //     position: absolute;
            //     width: 98%;
            //     height: 100%;
            //     left: 0;
            //     top: -27rem;
            //     font-size: 12px;
            //     left: -16rem;
            // }
            .parent{
                position: absolute;
                bottom: 0;
                width: 100%;
                font-size: 16px;
                @include breakpoint(smallScreen){
                    display: none;
                }
                .strip{
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    width: 40em;
                    height:3em;
                    background-color: $white;
                    &:nth-child(even) {
                        background-color: $black;
                    }
                    &-one {
                        width: 70%;
                    }

                    &-two {
                        width: 80%;
                    }

                    &-three {
                        width: 75%;

                    }

                    &-four {
                        width: 70%;

                    }

                    &-five {
                        width: 60%;

                    }

                    &-six {
                        width: 80%;

                    }

                    &-seven {
                        width: 90%;

                    }

                    &-eight {
                        width: 78%;

                    }

                    &-nine {
                        width: 88%;

                    }

                    &-ten {
                        width: 87%;

                    }
                }
            }
        }
        .data{
            margin-top: 9rem;
        .img-block{
            width: 100%;
            height: 100%;
            img{
                width: 55%;
                min-width: 310px;
                top: -1rem;
                position: relative;
                @media (max-width: 900px) {
                    min-width: auto;
                }
                @media (max-width: 767px) {
                    width:40%;
                    min-width: auto;
                }
            }
        }
        .text-block{
            position: absolute;
            top: 20%;
            left: 10%;
            @media (max-width: 1000px)and (min-width: 767px) {
                top: -18%;
                left: 23%;
                width: 89%;
            }
            @include breakpoint(largeMobile){
                position: relative;
                top: auto;
                left: auto;


            }
            h1{
                font-size: 5.6em;
                font-family: $ultra-font,sans-serif;
                color: #D92027;
                text-align: left;
                text-transform: uppercase;
                line-height: 50px;
                letter-spacing: -2px;

                @include breakpoint(largeMobile){


                font-size: 39px;
                font-family: "gotham-ultra",sans-serif;
                color: #D92027;
                text-align: left;
                text-transform: uppercase;
                line-height: 36px;
                letter-spacing: 0px;
            }
        }
            p{
                font-family: $thin-font,sans-serif;
                font-size: 21px;
                font-weight: bold;
                /*line-height: 35px;*/
                color: $light-black;
                text-align: left;
                margin-top: 3rem;
                
                @include breakpoint(mobile){
                    font-size: 15px;
                    line-height: 20px;
                }
               @media (max-width: 1200px){
                    font-size: 20px;
                }

            }
            .jc-btn{
                float: right;
                // width: 110px;
            }
        }
    }
    }


}



.third-section{
    position: relative;
    width: 100%;
    height: auto;
    background-color: $white;
    // margin-bottom: 5rem;
    .wrapper{
        padding: 3rem;
        .text_container{
            position: relative;
            margin-top: 13rem;
            z-index: 22;
            font-size: 10px;
            @include breakpoint(mobile){
                font-size: 4px;
            }
            @media (max-width: 1024px) {
                font-size: 6px;
                margin-top: 3rem;
            }
            .primary_text{
                font-size:56px;
                line-height: .9em;
                letter-spacing: -0.05em;
                text-transform: uppercase;
                text-align: left;
                font-family: $ultra-font,sans-serif;
            }
            .secondary_text{
                font-size: 21px;
                text-align: left;
                color: $light-black;
                font-family: $thin-font,sans-serif;
                margin: 3em 0px;
                font-weight: bold;
            }
        }
        .img-block{
            font-size: 16px;
            z-index: 10;
            right: auto;
            position: relative;
            top: 4rem;
            @include breakpoint(midScreen){
                font-size: 13px;
                z-index: 10;
                left: -6rem;
                top: 20rem;
                position: relative;
            }
            @media (max-width: 1500px) and (min-width: 1025px){
                font-size: 13px;
                z-index: 10;
                left: 1rem;
                top: 11rem;
                position: relative;
            }
            @media (max-width: 1024px) and (min-width: 700px){
                font-size: 10px;
                z-index: 10;
                left: 1rem;
                top: 7rem!important;
                position: relative;
            }
            @include breakpoint(smallScreen){
                top: 20rem;
            }
            @include breakpoint(largeMobile){
                // font-size: 13px;
                // z-index: 10;
                // left: -6rem;
                // top: 15.4rem;
                // position: relative;
                display: none;
            }
            img{
                width: 40em;
                position: absolute;
                left: -2rem;
            }
            // &-1{
            //     width: 40em !important;
            //     left: -4em;
            // }
            // &-2{
            //     width: 42em !important;
            //     top: -9.9em;
            // }
        }
    }   
}

// a{
//     margin-top: 10px;
// }
}
.round-img{
    border-radius: 50%;
    top: -5px;
    position: relative;
}
.section_two h1,.third-section h1,header .header-text .primary-text{
    font-size: 56px!important;
    line-height: .8!important;
}
.index-Page .rewards-system .section_two .data .text-block p,
.index-Page .third-section .wrapper .text_container .secondary_text{
    line-height: 1.2;
    margin-top: 32px!important;
    margin-bottom: 24px!important;
}
@media (max-width: 992px) {
    .section_two h1,.third-section h1,header .header-text .primary-text{
        font-size: 39px!important;
        line-height: .8!important;
    }
}
.about-challenge-page{
    font-family: sans-serif;
}