.login-page{
    background-color: $off-white;
    .login-page{
        width: 100vw;
        position: relative;
        margin-top: 110px;
        display: flex;
        justify-content: center;
        align-items: center;

        // @include breakpoint(minLargeScreen){
        //     margin-top: 5%;
        // }
        @include breakpoint(smallScreen){
            margin-top: 15%;
        }
        .wrapper{
            // @include centerer;
        }
        .data-block .primary-title{
            @include breakpoint(smallScreen){
                font-size: 2em;
            }
        }
        .remember-container{
            @include breakpoint(smallScreen){
                font-size: 10px;
            }
        }
       
    }
    footer{
        width: 100%;
        position: fixed;
        bottom: 0;
        margin: 0 !important;
    }
    .jc_form .label{
        position: relative;
        left: 7px !important;
        bottom: 7px  !important;
        color: #969696;
        text-transform: capitalize;
        font-family: $thin-font,sans-serif;
        font-size: 12px;
    }
    .jc_form .forget-password{
        font-family: $thin-font,sans-serif;
        font-size: 12px;
        text-transform: capitalize;
    }
    .form-group{
        position: relative;
    }
    // a{
    //     margin-top: 10px;
    // }
.jc_form .style-input input{
    width: 100%;
    padding: 15px!important;
    height: 46px;
    margin-bottom: 1rem;
    &:focus {
        outline: none;
        box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.33);
    }
}
    .primary-title{
        @media (max-width: 1100px) {
            font-size: 36px!important;
            *{
                font-size: 36px!important;;
            }
        }
    }
    .players-block-component{
        font-size: 8px!important;
        transform: scale(0.7);
        margin-top: 3rem;
    }
    .data-block{
     @media (max-width: 992px) {
         min-height: 500px;
     }
        @media (max-width: 600px) {
         min-height: 550px;
     }
    }
    .jc-btn{
     @media (max-width:1200px) {
         width: 180px!important;
     }
    }
}

.login-page .jc_form .style-input input{
    font-family: sans-serif;
}