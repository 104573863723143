@mixin font-face($name, $file) {
  @font-face {
    font-family: "#{$name}";
    src:url("../fonts/#{$file}.ttf") format("truetype"),
    url("../fonts/#{$file}.ttf") format("truetype"),
    url("../fonts/#{$file}.svg?#webfont") format("svg");
  }
}

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }

  @-moz-keyframes #{$name} {
    @content;
  }

  @-ms-keyframes #{$name} {
    @content;
  }

  @keyframes #{$name} {
    @content;
  }
}



// To center Any ELements
@mixin centerer {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@mixin topBottomStrip {
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 50px;
    top: 30px;
    z-index: -1;
    left: 0;
    background: rgb(255, 255, 255);
    background: -moz-linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#ffffff", GradientType=1);
}
&::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 50px;
    bottom: 0;
    z-index: 222;
    left: 0;
    background: rgb(255,255,255);
    background: -moz-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
}
}

//clear fix
@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

//Add Opacity
@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}




@mixin primary-gradient {
  background: rgb(56, 56, 56);
  background: -moz-linear-gradient(90deg, rgba(56, 56, 56, 1) 0%, rgba(0, 0, 0, 1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(56, 56, 56, 1) 0%, rgba(0, 0, 0, 1) 100%);
  background: linear-gradient(90deg, rgba(56, 56, 56, 1) 0%, rgba(0, 0, 0, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#383838", endColorstr="#000000", GradientType=1);
}

@mixin red-primary-gradient {
  background: rgb(219,29,29) !important;
  background: -moz-linear-gradient(90deg, rgba(219,29,29,1) 0%, rgba(244,106,106,1) 100%)  !important;
  background: -webkit-linear-gradient(90deg, rgba(219,29,29,1) 0%, rgba(244,106,106,1) 100%)  !important;
  background: linear-gradient(90deg, rgba(219,29,29,1) 0%, rgba(244,106,106,1) 100%)  !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#db1d1d",endColorstr="#f46a6a",GradientType=1) !important;
}





// (min-width: 1441px) and (min-width: 1681px)
//10240px
// 1023px
// 734px


//Screens Mixin 
@mixin breakpoint($point) {
  @if $point == default {
    @media only screen and (max-width: 1920px) and (max-height:1080px) {
      @content;
    }
  }

  @else if $point == largeScreen {
    @media only screen and (max-width:1920px) {
      @content;
    }
  }
  @else if $point == minLargeScreen {
    @media only screen and (min-width:1920px) {
      @content;
    }
  }

  
  @else if $point == midScreen {
    @media only screen and (max-width:1280px) {
      @content;
    }
  }


  @else if $point == largeMobile {
    @media only screen and (max-width:766px) {
      @content;
    }
  }
  @else if $point == smallScreenToLarge{
    @media only screen and (min-width:1025px) {
      @content;
    }
  }
  @else if $point == smallScreen{
    @media only screen and (max-width:1024px) {
      @content;
    }
  }


  @else if $point == ipad {
    @media only screen and (min-width:768px) and (max-width:1024px){
      @content;
    }
  }
  @else if $point == ipadLandScape {
    @media only screen and (orientation:landscape) and (min-width:768px) and (max-width:1024px){
      @content;
    }
  }
  @else if $point == mdScreen {
    @media only screen and (max-width:767px) {
      @content;
    }
  } 
  @else if $point == mobile {
    @media only screen and (max-width:480px) {
      @content;
    }
  } 
  @else if $point == xmobile {
    @media only screen and (max-width:375px) {
      @content;
    }
  } 


  // Lanscapes media qyeries
  @else if $point == scapeTablet {
    @media only screen and (orientation:landscape)
    and (min-width: 871px) 
    and (max-width: 1100px){
      @content;
    }
  } 

  @else if $point == scapeLgMobile {
    @media only screen and (orientation:landscape)
    and (min-width: 571px) 
    and (max-width: 870px){
      @content;
    }
  } 

  @else if $point == scapeMobile {
    @media only screen and (orientation:landscape)
    and (min-width: 190px) 
    and (max-width: 570px){
      @content;
    }
  } 


  @else if $point == allLandscapes {
    @media only screen and (orientation:landscape)
    and (min-width: 100px) 
    and (max-width: 1025px){
      @content;
    }
  } 

  @else if $point == scapeMidMobile{
    @media only screen and (orientation:landscape)
    and (min-width: 600px) 
    and (max-width: 680px){
      @content;
    }
  } 



}


