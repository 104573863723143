.navbar {
    background-color: rgba(240, 242, 245,0.5);
    -webkit-backdrop-filter: saturate(180%) blur(10px);
    backdrop-filter: saturate(180%) blur(10px);
    .navbar-brand {
        &__img {
            width: 82px;
            height: 82px;
            @include breakpoint(smallScreen){
                width: 42px;
                height: 42px;
                margin: 2rem 0px;
            }
        }
    }



    .navbar-list {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 10px;
        list-style: none;
        margin: 0rem auto;
        margin-right: 0;
        @media screen and (min-width:1025px) and (max-width:1200px){
            font-size: 7px;
            .jc-btn{

                width: 106px;
                height: 40px;
            }
        }
        @include breakpoint(smallScreen){
            display: none;
            font-size: 7px;
        }
        @include breakpoint(largeMobile){
            display: none;
        }
        // .userProfile-btn{
        //     a{
        //         img{

        //         }
        //     }
        // }
   
        &__item {
            margin: 0 1rem;
            display: flex;
            justify-content: space-between;
            align-items: center;

            a {
                position: relative;
                text-decoration: none;
                text-transform: uppercase;
                color: $black;
                font-size: 1.4em;
                // margin-top: .5rem;
                position: relative;
                transition: all .4s ease-in-out;
               
            }
          
        }
    }
    .menu-btn{
        display: none ;
    @include breakpoint(smallScreen){
        position: absolute;
        right: 5%;
        width: 30px;
        height: 35px;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0px 10px 30px rgba(0,0,0,0.26);
        top: 30%;
        z-index: 99999;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
}
.nav-menu-overlay{
    display: none;
}
.nav-menu--active{

    @include breakpoint(smallScreen){
        .navbar-brand{
            z-index: 99999;
            .navbar-brand__img{
                margin: 0 ;
                outline: none;
            }
        }
        .nav-menu-overlay{
            a,label{
                font-family: sans-serif;

            }
            display: none;
            position: absolute;
            width: 0vw;
            top: 0;
            height: 100vh;
            background-color: rgba(240, 242, 245,.97);
            -webkit-backdrop-filter: saturate(180%) blur(10px);
            backdrop-filter: saturate(180%) blur(10px);
            display: none;
            left: 0;
            z-index: 9999;
            justify-content: center;
            align-items: center;
            ul{
                list-style: none;
            }
            li {
                position: relative;
                display: flex;
                justify-content: left;
                align-items: center;
                a{
                    text-decoration: none;
                    text-transform: uppercase;
                    color: $black;
                    font-size: 1.4em;
                    position: relative;
                    transition: all .4s ease-in-out;

                }
                .jc-btn{
                    width: 120px;
                    a{
                        font-size: 11px;
                    }
                }
            }
        }
      
    }
}
.dropdown-toggle::after{
    content: none;
}
.menu-item{
    &:active,&:focus{
        outline: none;
        box-shadow:none;
    }
}
label.dropdown-item{
    width: 100%;
    text-decoration: none;
    text-transform: uppercase;
    color: #000;
    font-size: 1.4em;
    position: relative;
    margin-top: 10px;
    transition: all .4s ease-in-out;
}
.modal-margin{
    margin-bottom: 75px;
}
.padding-2-em{
    padding: 2em!important;
}