.board-tabs {
    width: 100%;
    // background: $white;
    border-radius: 3em;
    margin: 2rem 0;
    display: flex;

    .nav-tabs {
        border-radius: 3em;
        position: relative;
        display: block !important;
        top: 10%;
        z-index: 3;
        right: -1em;
        user-select: none;
        .nav-link {
            box-shadow: 0px 10px 30px #00000029;
            height: 7em;
            background-color: #fff;
            border-top-left-radius: 2em;
            border-bottom-left-radius: 2em;
            display: flex;
            justify-content: center;
            align-items: center;
            filter: grayscale(1);
            img{
                width: 5em;
            }
        }
        .active{
            filter: grayscale(0);
            z-index: 222;
            pointer-events:auto;

        }
    }

    .content {
        z-index: 5;
        box-shadow: 0px 10px 30px #00000029;
        border-radius: 3em;
        padding: 2em;
        background-color: rgb(255, 255, 255);
        @include breakpoint(scapeLgMobile){
          width: 100%;
        }
    }
}