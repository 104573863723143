
.animate__delay-400s {
   animation-delay: 0.400s !important;
}
.animate__delay-500s {
    animation-delay: 0.500s !important;
 }

 .animate__delay-600s {
    animation-delay: 0.600s !important;
 }

 .animate__delay-700s {
    animation-delay: 0.700s !important;
 }
 .animate__delay-800s {
    animation-delay: 0.800s !important;
 }
.animate__delay-900s {
    animation-delay: 0.900s !important;
 }
.animate__delay-1000s {
    animation-delay: 1.000s !important;
 }
.animate__delay-1100s {
    animation-delay: 1.100s !important;
 }
.animate__delay-1200s {
    animation-delay: 1.200s !important;
 }
.animate__delay-1300s {
    animation-delay: 1.300s !important;
}
.animate__delay-1400s {
    animation-delay: 1.400s !important;
}
.animate__delay-1500s {
    animation-delay: 1.500s !important;
}
html{
    overflow-x: hidden !important;

}
*{
    box-sizing: border-box !important;
    -webkit-box-sizing: border-box !important;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
}

body{
    font-family: 'med',Arial, Helvetica, sans-serif;
    overflow: hidden ;
}
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	// margin: 0;
	padding: 0;
	border: 0;
	// font-size: 100%;
	// vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

::-webkit-scrollbar {
    width: 2px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $red; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #b30000; 
  }


.clearfix{
    &::after {
        display: block;
        content: "";
        clear: both;
      }
}
.stopScrollInBody{
    html,body{
        @include breakpoint(smallScreen){
            overflow-x:  hidden !important;
            overflow-y:  hidden !important;
        }
    }
}

.overflow-hide{
    overflow:hidden !important;
}

.uppercase{
    text-transform: uppercase !important;
}


.red-word{
    color: $red !important;
}
.white-word{
    color: $white !important;
}
.black-word{
    color: $black !important;
}

.margin_top_bottom_50{
    margin: 5rem 0;
}
.margin_top_20{
    margin: 2rem 0;
}
.flex_lg_col{
    @include breakpoint(largeScreen){
        flex-direction: column;

    }
}
.flex_md_col{
    @include breakpoint(midScreen){
        flex-direction: column;
    }
}
.flex_small_col{
    @include breakpoint(largeMobile){
        flex-direction: column;
    }
}


.margin_top_20_sm{
    @include breakpoint(smallScreen){
        margin: 2rem 0;


    }
}
.margin_left_12{
    @include breakpoint(smallScreen){
    margin-left: 2.4rem !important;
    }
}

.margin_bottom_20{
        margin-bottom: 2rem ;
}

.margin_5{
    margin: .5rem 0;

}
.margin_left_15{
    margin-left: 2.4rem !important;
}


.boldClass{
    //font-family: $thin-font !important;
    color: $black !important;
    font-weight: bold;
}
.thin-font{
    font-size: 3rem !important;
    font-family: $med-font,sans-serif !important;
}


.stopClick{
    pointer-events: none !important;
}



// .flex-column{
//     display: flex !important;
//     flex-direction: column !important;
// }

.data-block{
    margin: 2rem ;
    position: relative;
    font-size: 0.7rem;
    left: 4rem;
    @include breakpoint(midScreen){
        left: auto;
    }
    @include breakpoint(mobile){
        left: auto;

    }
    .primary-title{
        font-size: 5em;
        font-family: "gotham-ultra",sans-serif;
        text-align: left;
        text-transform: uppercase;
        position: relative;
        @include breakpoint(midScreen){
            font-size: 3em;
            left: auto;
        }
        @include breakpoint(mobile){
            font-size: 2em;
            left: auto;

        }
    }
    @include breakpoint(midScreen){
        margin: 0 ;
    }
}




// landScape classes

//hidden classes
.d-lg-scape-none{@include breakpoint(scapeTablet){display: none !important}}
.d-md-scape-none{@include breakpoint(scapeLgMobile){display: none !important}}
.d-sm-scape-none{@include breakpoint(scapeMobile){display: none !important}}
//show classes
.d-lg-scape-block{@include breakpoint(scapeTablet){display: block !important}}
.d-md-scape-block{@include breakpoint(scapeLgMobile){display: block !important}}
.d-sm-scape-block{@include breakpoint(scapeMobile){display: block !important}}


.jc-shadow{
    box-shadow: 0px 10px 30px rgba(0,0,0,0.13);
}

.landScapeMsg{
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99999999999;
    pointer-events: none !important;
    background-color: $off-white;
    @include breakpoint(allLandscapes){
        display: none !important;
    }
    @include breakpoint(smallScreenToLarge){
        display: none !important;
    }
    .text{
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 100%;
        height: 100%;
        padding: 2rem;
        h1{
            color: $black;
            font-family: $black-font,sans-serif;
            letter-spacing: 0px;
            color: #ff6565;
            text-transform: uppercase;
            font-size: 2em;
            margin: 1rem 0;
        }
    }
}


.adjustHrefForMac{
    a{
        margin-top: 10px;
        transition: all .2s ease-in-out;
    }
}