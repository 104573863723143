.inner--level-page {
    height: 100vh;
    .navbar {
        position: relative !important;
    }

    .wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 10px;
        position: relative;
        margin: 8rem auto 10rem auto;
    
        @include breakpoint(scapeLgMobile) {
            font-size: 6px;
         
        }

        @include breakpoint(scapeMobile) {
            font-size: 6px;
         
        }
        .level-cover {
            position: relative;
            width: 100%;
            height: 100%;
            max-height: 43.1em;
            overflow: hidden;
            border-radius: 1.3em;

            img {
                width: 100%;
                height: 100%;
            }

        }

        .level-videos {
            position: relative;
            display: flex;
            justify-content: space-between;
            margin: 1rem 0;
            width: 100%;

            .video-container {
                flex-basis: 31.5%;
                height: 26.2em;
                min-height: 26.2em;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                cursor: pointer;
                .video {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    border-radius: 2em;
                    background-color: rgb(58, 54, 63);
                    overflow: hidden;
                    .locked-overlay{
                        display: none;
                    }
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
                .lock{
                    .locked-overlay{
                        position: absolute;
                        display: flex !important;
                        justify-content: center;
                        align-items: center;  
                        width: 100%;
                        height: 100%;z-index: 22;
                        background-color: rgba(9,9,9,.8);
                        img{
                            width: 6em;
                            height: 7em;    
                        }
                    }
                    .video-cover{
                        filter: blur(3px) grayscale(100%) !important;
                    }
                }
                .details {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    flex-direction: row;
                    padding: 0 1em;
                    .title{
                        font-size: 1.5em;
                        margin-top: 1.2em;
                    }
                    .time{
                        font-size: 1.2em;
                        color: $light-grey;
                        font-family: $med-font,sans-serif;
                        min-width: 7em;
                    }
                }
            }
        }
    }
}