@import "~animate.css/animate.css";
@import './libs/all.min.css';


@import './helpers/variables';
@import './helpers/mixins';
@import './helpers/fonts';




// import bootstrab
@import './../../node_modules/bootstrap/scss/bootstrap.scss';
@import './../../node_modules/wowjs/css/libs/animate.css';
@import './../../node_modules/swiper/swiper.scss';
@import './../../node_modules/video.js/src/css/video-js.scss';
@import './libs/videojs.markers.scss';





// import pages

/////Home Page
@import './pages/index';
@import './pages/signUp';
@import './pages/login';
@import './pages/forget_password';
@import './pages/reset_password';
@import './pages/register';
@import './pages/game';
@import './pages/profile';
@import './pages/userProfile';
@import './pages/video_page';
@import './pages/inner_level';







// import components
/////// Header 
@import './components/header';
@import './components/navbar';
@import './components/footer';



// BUTTONS
@import './btns/primary-btn';



// html components
@import './components/players';
@import './components/jc-form';
@import './components/players_board';
@import './components/tabs-board';
@import './components/preloader';












// common scss files which include body and html style 
@import './helpers/common';
@import './../../node_modules/cropperjs/dist/cropper.css';
@import "./../../node_modules/wowjs/css/libs/animate.css";
