.players-block-component {
    position: relative;
    float: right;
    font-size: 8px;
    z-index: 20;
    right: -24%;
    margin-top: 5rem;
    .img_converter{
        background: url('./../imgs/player-3.png') no-repeat center center;
        position: relative;
        width: 45em;
        height: 40em;
        background-size: contain;
        z-index: 22;
    }
    @include breakpoint(smallScreen) {
        right: -41%;
        font-size: 6px;
    }

    @include breakpoint(largeMobile) {
        right: -35%;
    }

    @include breakpoint(mobile) {
        font-size: 9px;
        right: -25%;
    }

    @include breakpoint(xmobile) {
        font-size: 7px;
        right: -30%;
    }
    @include breakpoint(scapeTablet) {
        font-size: 4.5px;
        right: -30%;
        margin: 0;
    }
    @include breakpoint(scapeLgMobile) {
        font-size: 3.5px;
        right: -30%;
        margin: 0;
    }
    &__content {
        width: 60em;
        height: 60em;

        img {
            width: 100%;
            height: 100%;
            z-index: 20;
            position: relative;
        }
    }

    // strips container
    &__strips-container {
        width: 36em;
        height: 38em;
        position: absolute;
        left: 52%;
        top: 20%;
        transform: translate(-50%, -50%);
        margin-left: 1em;

        //parent container
        &-parent {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            .strip {
                position: relative;
                width: 3.7em;
                height: 25em;
                background-color: $white;
                box-shadow: 0 0 2px 3px rgba(9,9,9,.05);
                &:nth-child(even) {
                    background-color: $black;
                }

                &-one {
                    height: 34.3em;
                    top: 11em;

                }
                &-two {
                    height: 45em;
                    top: 3eem;
                }

                &-three {
                    height: 30em;
                    top: 2em;
                }

                &-four {
                    height: 44em;
                    top: -5em;

                }

                &-five {
                    height: 46em;
                    top: -8em;

                }

                &-six {
                    height: 40.2em;
                    top: -1em;
                }

                &-seven {
                    height: 42em;
                    top: -3em;

                }

                &-eight {
                    height: 30em;
                    top:2em;

                }

                &-nine {
                    height: 34em;
                }
                &-ten {
                    height: 25em;

                }
            }
        }
    }
}