.register-page {
  
    background-color: $off-white;
    #skipBtn{
        display: none;
    }
    .players-block-component{
        top: 50px!important;
            right: 0 ;
            margin-top: 0;
            &__content{
                width: 32em;
                height: 60em;
            #play_2,#play_3{
                display: none;
                opacity: 0;
            }
            #play_2
            {
                width: 45em;    
                left: -66px;
                @media (max-width: 1100px) {
                    left: -3rem!important;
                }
            }
        }
        .players-block-component__strips-container{

    top: 45%;

        }
    }

    .register-page {
        width: 100%;
        height: 100%;

        .wrapper {
            margin-top: 10%;
            position: relative;
            @media (max-width: 767px) {
                margin-top: 20%;
            }
            /*@include breakpoint(scapeLgMobile) {
                margin-top: 42%;
            }

            @include breakpoint(scapeMobile) {
                margin-top: 42%;
            }*/

            .data-block {
                padding: 0;
                margin: 0;
                @media (max-width: 1200px) and (min-width: 1100px){
                    margin-top: 2rem;
                }
                .primary-title {
                    font-size: 4em;

                    @include breakpoint(scapeMobile) {
                        font-size: 2em;
                        *{
                            font-size: 2em!important;
                        }
                    }
                    @media (max-width: 767px) {
                        font-size: 1.8em;
                        *{
                            font-size: 1.8em!important;;
                        }
                    }
                    @media (max-width: 1100px) {
                        font-size: 36px;
                        *{
                            font-size: 36px!important;;
                        }
                    }
                }

                @include breakpoint(scapeTablet) {
                    .primary-title {
                        font-size: 3.3em;
                    }

                    margin-top: 2rem;
                }

                @include breakpoint(scapeLgMobile) {
                    /*.primary-title {
                        font-size: 1.7em;
                    }*/
                }

                .form-group {
                    margin: 0 !important;
                }

                .timeline {
                    font-size: 10px;
                    position: relative;
                    width: 100%;
                    height: auto;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin: .5rem 0;
                    text-align: center;

                    &::before {
                        position: absolute;
                        content: '';
                        left: 10px;
                        top: 50%;
                        width: 95%;
                        // background-color: #a9d0b1;
                        border-top: 8px dotted $light-grey;
                        height: .5em;
                    }

                    &-progress {
                        position: absolute;
                        width: 0%;
                        left: 10px;
                        top: 50%;
                        z-index: 20;
                        // border-top: .5em dotted $dark-green;   
                        background-color: $dark-green;
                        height: .8em;

                    }

                    .is-complete {
                        background-color: $green !important;
                        border: .4em solid $dark-green !important;

                        .circle-title {
                            font-family: $black-font,sans-serif;
                        }
                    }

                    .is-current {
                        background-color: $circle-black !important;
                        border: .4em solid $red !important;

                        .circle-title {
                            font-family: $thin-font,sans-serif;
                        }
                    }

                    .circle {
                        width: 4em;
                        height: 4em;
                        background-color: $disabled;
                        border-radius: 50%;
                        border: .4em solid $light-disable;
                        z-index: 22;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        &-title {
                            font-size: 13px;
                            position: absolute;
                            bottom: -2.5rem;
                            width: 7em;
                            font-family: $thin-font,sans-serif;
                            color: $light-black;
                        }
                    }

                    .step-count {
                        font-family: $black-font,sans-serif;
                        color: $white;
                        font-size: 2em;
                    }
                }

                //changed-container
                .changed-container {
                    margin-top: 3.5rem;
                    min-height: 440px;
                    .steps-title {
                        font-size: 2.5rem;
                        @media (max-width: 1100px) {
                            font-size: 2rem;
                        }
                    }

                    .step_two {
                        display: none;
                        // min-height: 421px;


                        /* Utility to visually hide contextual info */
                        .sr-only {
                            position: absolute;
                            width: 1px;
                            height: 1px;
                            padding: 0;
                            margin: -1px;
                            overflow: hidden;
                            border: 0;
                        }




                        /* Form stuff */
                        .form-wrapper {
                            display: block;
                            border-radius: 10px;
                            margin: 20px 0;
                            width: 100%;
                        }

                        .form-intro {
                            text-align: center;
                        }

                        .form-message {
                            text-align: center;
                            border: 2px solid currentColor;
                            border-radius: 10px;
                            padding: 1.5rem 1rem;

                        }

                        .form-message--success {
                            color: DarkGreen;
                            background-color: Honeydew;
                        }

                        .form-message--error {
                            color: DarkRed;
                            background-color: MistyRose;
                        }

                        .form-message--alert {
                            color: DarkBlue;
                            background-color: Azure;
                        }


                        /* Style everything as buttons */
                        .button {
                            position: relative;
                            background-color: white;
                            border: none;
                            border-radius: 5px;
                            display: inline-block;
                            margin-right: 10px;
                            margin-bottom: 20px;
                            font-size: 1rem;
                            padding: 0;
                            border-radius: 29px;
                            cursor: pointer;
                        }

                        .button span {
                            padding: .5rem 1.2rem;
                            border: 2.5px solid black;
                            // height: 56px;
                            border-radius: inherit;
                            display: flex;
                            justify-content: center;
                            align-items: flex-end;
                            letter-spacing: 0px;
                            color: $black;
                            font-family: $med-font,sans-serif;
                            font-size: 16px;
                            user-select: none;
                        }

                        .button input:focus~span,
                        .button:focus {
                            outline: none;
                            // box-shadow: 0 0 1px 3px DodgerBlue;
                        }

                        .button input:checked~span {
                            color: white;
                            @include primary-gradient;

                        }
                    }

                    .step_three {
                        display: none;
                        .change_photo{
                            position: relative;
                            display: block;
                            /* padding-bottom: 122px; */
                            margin-left: 37px;
                            color: dodgerblue;
                            font-family: $thin-font,sans-serif;
                            text-decoration: underline;
                            cursor: pointer;
                        }
                        .drop-zone {
                            position: relative;
                            width: 100%;
                            height: auto;
                            margin: 1rem 0;
                        
                            &--container {
                                max-width: 150px;
                                height: 150px;
                                // padding: 25px;
                                overflow: hidden;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                text-align: center;
                                font-family: "Quicksand", sans-serif;
                                font-weight: 500;
                                font-size: 20px;
                                cursor: pointer;
                                color: #cccccc;
                                border:3px dashed $light-grey;
                                border-radius: 30px;
                                #uploaded_image{
                                    width: 100%;
                                    height: 100%;
                                    display: none;
                                }
                                
                                .modal{
                                    max-height: 720px !important;
                                    #finalImage{
                                        position: relative;
                                        width: 200px;
                                        height: 200px;
                                        border: 1px solid rebeccapurple;
                                        border-radius: 30px;
                                        margin: 30px auto;
                                    }
                                    #view_image{
                                        border-radius: 30px;
                                        overflow: hidden;
                                    }
                                    img{
                                        width: 100%;
                                        height: 100%;
                                    }
                                    #croppedImage{
                                       overflow: hidden;
                                       border: 1px solid rebeccapurple;
                                       border-radius: 30px;
                                       img{
                                           width: 200px !important;
                                           height: 200px !important;
                                       }
                                    }
                                }
                              }
                              &__prompt{
                                width: 84%;
                              }
                              
                              .drop-zone--over {
                                border-color:$green;
                                
                              }
                              
                              .drop-zone__input {
                                display: none;
                              }
                              
                              .drop-zone__thumb {
                                width: 100%;
                                height: 100%;
                                border-radius: 10px;
                                overflow: hidden;
                                background-color: #cccccc;
                                background-size: cover;
                                position: relative;
                              }
                              
                              .drop-zone__thumb::after {
                                content: attr(data-label);
                                position: absolute;
                                bottom: 0;
                                left: 0;
                                width: 100%;
                                padding: 5px 0;
                                color: #ffffff;
                                background: rgba(0, 0, 0, 0.75);
                                font-size: 14px;
                                text-align: center;
                              }
                              
                        }

                        form {
                            .agreement-container {
                                margin: 1rem 0;
                            }

                            .agreement {
                                text-align: left;
                                font-family: $thin-font,sans-serif;
                                letter-spacing: -0.6px;
                                color: $light-grey;
                                font-size: 13px;

                            }
                        }
                    }
                    @media (max-width: 767px) {
                        margin-bottom: 120px;
                    }
                }
            }

        }

        input {
            padding: 1.6rem !important;

            @include breakpoint(scapeLgMobile) {
                padding: 1rem !important;
            }

            @include breakpoint(scapeMobile) {
                padding: 1rem !important;
            }


        }
    }

    footer {
        margin: 0 !important;
        position: fixed;
        bottom: 0;
        width: 100%;
    }

    .select-menu {
        position: relative;
        z-index: 1;
        font-weight: 500;
        font-size: 14px;
        line-height: 25px;

        select,
        .button {
            font-family: inherit;
            margin: 0;
            border: 0;
            text-align: left;
            text-transform: none;
            -webkit-appearance: none;
        }

        select {
            pointer-events: none;
            user-select: none;
            opacity: 0;
            padding: 16px 36px 16px 12px;
            visibility: hidden;
            font-weight: 500;
            font-size: 14px;
            line-height: 25px;
        }

        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            transform: translateY(var(--t));
            transition: opacity .3s ease, transform .4s cubic-bezier(.2, .9, .4, 1.1);

            li {
                padding: 8px 36px 12px 12px;
                // padding: 8px 36px 8px 12px;
                cursor: pointer;
            }
        }

        &>ul {
            background: $white;
            color: $light-black;
            border-radius: 6px;

            li {
                transition: color .3s ease;

                &:hover {
                    color: $black;
                }
            }
        }

        .button {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            padding: 0;
            z-index: 1;
            width: 100%;
            display: block;
            overflow: hidden;
            border-radius: 6px;
            color: $light-black;
            background: $black;

            em {
                --r: 45deg;
                display: block;
                position: absolute;
                right: 12px;
                top: 0;
                width: 7px;
                height: 7px;
                margin-top: 13px;

                &:before,
                &:after {
                    --o: .4;
                    content: '';
                    width: 7px;
                    height: 7px;
                    opacity: var(--o);
                    display: block;
                    position: relative;
                    transition: opacity .2s ease;
                    transform: rotate(var(--r)) scale(.75);
                }

                &:before {
                    border-left: 2px solid var(--icon);
                    border-top: 2px solid var(--icon);
                    top: 1px;
                }

                &:after {
                    border-right: 2px solid var(--icon);
                    border-bottom: 2px solid var(--icon);
                    bottom: 1px;
                }
            }
        }

        &:not(.open) {
            &>ul {
                opacity: 0;
                pointer-events: none;
            }
        }

        &.open {
            &.tilt-up {
                animation: tilt-up .4s linear forwards;

                .button {
                    em {
                        &:before {
                            --o: 1;
                        }
                    }
                }
            }

            &.tilt-down {
                animation: tilt-down .4s linear forwards;

                .button {
                    em {
                        &:after {
                            --o: 1;
                        }
                    }
                }
            }
        }
    }

    @keyframes tilt-up {

        40%,
        60% {
            transform: perspective(500px) rotateX(8deg);
        }
    }

    @keyframes tilt-down {

        40%,
        60% {
            transform: perspective(500px) rotateX(-8deg);
        }
    }


    // Center & dribbble
    .dribbble {
        position: fixed;
        display: block;
        right: 20px;
        bottom: 20px;

        img {
            display: block;
            height: 28px;
        }
    }

    //////////////////
    /*
* POP UPS TO SHOW BADGES
*/
.pop-up--normal {
        z-index: 222;
        display: none;
}
    .badge-popUp {
        position: fixed;
        width: 100vw;
        height: 100vh;
        z-index: 222;
        background-color: $off-white;
        top: 0;
        left: 0;
        display: none;

        .wrapper {
            position: absolute;
            width: 100%;
            z-index: 222;
            height: 100%;
            left: 0;

            .parent {
                position: relative;
                width: 100%;
                height: 100%;
                background-color: $off-white;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                font-size: 4px;
                @include breakpoint(smallScreen){
                    font-size: 4px;
                }
                .title {
                    font-size: 9em;
                    text-transform: uppercase;
                    font-family: $black-font,sans-serif;
                    color: #5CCE72;
                    @media (max-width: 600px) {
                        font-size: 8em;
                    }
                }
                .sub-title {
                    font-size: 5em;
                    font-family: $med-font,sans-serif;
                    color: $light-grey;
                    text-align: center;
                }
                .shape {
                    width: 85em;
                    height: 76em;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .span-container{
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        margin: 0 auto;
                        width: 85em;
                        height: 76em;
                        text-align: center;
                        transition: all 15s ease-in-out;
                        animation: rotate_span 15s ease-in infinite forwards;

                        @include keyframes(rotate_span){
                            0%{
                                transform: rotate(0deg);
                            }
                            50%{
                                transform: rotate(160deg) ;
                            }
                            80%{
                                transform: rotate(260deg) ;

                            }
                            100%{
                                transform: rotate(360deg) ;

                            }
                        }
                    }
                    img {
                        width: 70%;
                        height: 70%;   
                        z-index: 22;
                    }

                    span {
                        width: 4em;
                        height: 80em;
                        background-color: rgb(255, 255, 255);
                        position: absolute;

                        &:nth-child(odd) {
                            background-color: rgb(0, 0, 0);
                        }

                        &:nth-child(2) {
                            background-color: rgb(0, 0, 0);
                            transform: rotate(-121deg);
                        }

                        &:nth-child(3) {
                            background-color: rgb(255, 255, 255);
                            transform: rotate(-221deg);
                        }

                        &:nth-child(4) {
                            background-color: rgb(0, 0, 0);
                            transform: rotate(-265deg);
                        }

                        &:nth-child(5) {
                            background-color: rgb(255, 255, 255);
                            transform: rotate(-284deg);
                        }

                        &:nth-child(6) {
                            transform: rotate(-328deg);
                        }

                        &:nth-child(6) {
                            background-color: rgb(255, 255, 255);
                            transform: rotate(-425deg);

                        }

                        &:nth-child(6) {
                            transform: rotate(210deg);


                        }
                    }
                }
            }
        }
    }

   .pop-up--badge{
       canvas{
        position: absolute;
        left: 0;
        width: 100%;
        z-index: 2222;
           @media (max-width: 800px) {
               width: auto;
           }
       }
        .wrapper{
            position: absolute;
            width: 100%;
            z-index: 222;
            height: 100%;
            left: 0;
            max-width: 1170px;
            /* margin: 0 auto; */
            right: 0;
            margin: 0 auto;
        }
    }

    .jc_form .style-input input{
        width: 100%;
        padding: 15px!important;
        height: 46px;
        margin-bottom: 1rem;
        &:focus {
            outline: none;
            box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.33);
        }
    }
    .step_one {
        .jc-select{
            margin: 0;
        }
.jc-shadow{
    padding: 15px!important;
    margin-bottom: 1rem;
    margin-top: 0;
}
        div:focus {

            outline: none;
            box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.33);
        }

        ::-webkit-input-placeholder { /* Edge */
            color: #969696;
        }

        :-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: #969696;
        }

        ::placeholder {
            color: #969696;
        }
    }
    .caret-down{
        position: absolute;
        top: 15px;
        right: 15px;
    }
.modal-content{
    margin-bottom: 110px;
}
    .img-container{
        img{
            object-fit: contain;
            width: 100%!important;
        }
    }
    .max-200{
        overflow-x: hidden;
        overflow-y: scroll;
        max-height: 200px;
    }
}


.jc-select {
    margin: 1rem 0;
}

.margin_5 {
    margin: 0.5rem 0 !important;
}

.left40 {
    left: 40px !important;
}


.jc-select>div.caption {
    position: relative;
    background-color: $white;
    // padding: 1.6rem;
    padding: 23px 16px 23px 16px;
    cursor: pointer;
    font-family: $med-font,sans-serif;
    border-radius: 15px;
    color: $light-grey;
    font-size: 12px;
    border: 2px solid transparent;

    @include breakpoint(scapeLgMobile) {
        padding: 1rem !important;
    }

    @include breakpoint(scapeMobile) {
        padding: 1rem;
    }



}

.jc-select>div.list {
    position: absolute;
    background-color: $white;
    width: 92.8%;
    border-radius: 0 0 3px 3px;
    display: none;
    border-radius: 15px;
    max-height: 200px;
    overflow-y: scroll;
    overflow-x: hidden;
    color: $light-grey;
    z-index: 222;
    // top: 99px;
    top: 55px;
}

.jc-select>div.list>div.item {
    padding: 11px 24px;
    cursor: pointer;
}

.jc-select>div.list>div.item.selected {
    font-weight: bold;
    color: $black;

}

.jc-select>div.caption:hover,
/*.jc-select>div.list>div.item:hover {
    border-color: $black;
    color: $black;

}*/

.jc-select.open>div.caption {
    border-radius: 15px;

}

.jc-select.open>div.list {
    display: block;
}

// .register-page {
//     a{
//         margin-top: 10px;
//     }
//     button span{
//         margin-top: 10px;
//     }

// }
.register-page .jc_form .style-input input,.register-page .step_one .jc-shadow,.register-page .register-page .wrapper .data-block .changed-container .step_two .button span{
 font-family: sans-serif;
}