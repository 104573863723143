
            .player_components {
                padding: 0;
                list-style: decimal;
                // margin: 2rem 0;
                margin: 2rem 1rem 0rem 0rem;
                max-height: 45em;
                overflow-y: scroll;
                .players {
                    position: relative;
                    display: flex;
                    align-items: center;
                    .counter {
                        font-weight: bold;
                        font-size: 2em;
                        width: 16px;
                        margin-right: .5rem;
                        line-height: 1;
                        margin-left: .5rem;
                    }
                    .playerImg {
                        border-radius: 50%;
                        width: 6em;
                        height: 6em;
                    }
    
                    .data {
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
    
                        .playerName {
                            text-align: left;
                            font-family: $black-font,sans-serif;
                            letter-spacing: 0px;
                            color: $black;
                            text-transform: capitalize;
                            font-size: 3em;
                            margin-bottom: .3em;
                        
                        }
                        .schoolName{
                            display: flex;
                            color: $light-grey;
                            font-size: 1.8em;
                        }
                 
                    }
                    .exp {
                        position: absolute;
                        right: 7%;
                        text-align: left;
                        font-family: $med-font,sans-serif;
                        letter-spacing: 0px;
                        color: $black;
                        text-transform: capitalize;
                        // font-size: 3em;
                        font-size: 2em;
                        margin-top: -8px;

                        img {
                            width: 20px;
                            margin-top: -5px;
                            position: relative;
                            height: 20px;
                        }
                    }
                }
            }