$red :#D92027;
$white :#FFFFFF;
$off-white:#F0F2F5;
$black:#000000;
$light-black:#555555;
$light-grey:#969696;
$disabled:#B8BABE;
$light-disable:#D3D4D6;
$green:#5CCE72;
$dark-green:#42A355;

//timeline colors
$circle-black:#292929;





// fontsize
$font-14:14px;
$font-30:30px;
$font-40:40px;
$font-50:50px;
$font-60:60px;
$font-70:70px;
$font-80:80px;
$font-90:90px;



// fontFamilies
$thin-font:'thin';
$med-font:'med';
$bold-font:'bold';
$black-font:'black';
$ultra-font:'gotham-ultra';




