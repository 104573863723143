.sign-page{
        background-color: $off-white;
        .sign-page-content {
            width: 100%;
            height: 100vh;
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 496px;
        .wrapper {
            font-size: 10px;
            text-align: center;
            @include breakpoint(mdScreen) {
                font-size:7px;
            }
            @include breakpoint(mobile) {
                font-size:5px;
            }
            @include breakpoint(xmobile) {
                font-size:5px;
            }
             @include breakpoint(scapeLgMobile) {
                font-size: 4px;
                }
        
                @include breakpoint(scapeMobile) {
                font-size: 4px;
                }
                img{
                    width: 30em;
                    margin: 1rem;
                }
            .primary-title {
                text-align: center;
                position: relative;
                font-size: 6em;
                text-transform: uppercase;
                font-family: $ultra-font,sans-serif;
             
            }
    
            .jc-btn {
                width: 370px;
                margin: 1rem auto;
                a{
                    font-size: 1.7em;
                    @include breakpoint(scapeLgMobile) {
                        font-size: 3.7em;
                    }
                    @include breakpoint(scapeMobile) {
                        font-size: 3.7em;
                    }
                }
                @include breakpoint(largeMobile){
                    height: 46px;
                }
                @include breakpoint(largeMobile){
                    width: 270px;
                }
    
            }
    
            .jc-btn__red {
                border: none;
                .overlay {
                    @include red-primary-gradient;
                }
                &:hover .overlay {
                    transition: all .4s ease-in-out;
                }
                &:hover{
                    border: 1px solid $red;
                }
    
            }
        }
    }
    footer{
        margin-top: 0 !important;
        position: fixed;
        bottom: 0;
        width: 100%;
    }
}


// .sign-page{
//     a{
//         margin-top: 10px;
//     }
//     button span{
//         margin-top: 10px;
//     }
// }