.profile-page {
    .schoolName{
        font-family: sans-serif;
    }
    background: $off-white;
    .wrapper{
    margin-top: 15%;

    }

    
    .container {
        @include breakpoint(scapeLgMobile) {
            width: 100%;
            max-width: 100%;
            padding: 0rem 4rem;
        }

        @include breakpoint(scapeMobile) {
            width: 100%;
            max-width: 100%;
            padding: 0rem 4rem;
        }
    }
    .board-tabs .nav-tabs .nav-link {
        margin-top: 0 !important;
    }
    .live_board {
        padding: 2rem;
        font-size: 10px;
        position: relative;
        font-size: 10px;

        @include breakpoint(scapeLgMobile) {
            font-size: 8px;
        }

        @include breakpoint(scapeMobile) {
            font-size: 6px;
        }

        .primary-title {
            color: $black;
            text-align: left;
            font-family: $black-font,sans-serif;
            letter-spacing: 0px;
            text-transform: uppercase;
            font-size: 3em;
        }

        .board {
            min-height: 150px;
            padding: 2em 0em 0em 2em;
            background-color: $white;
            margin: 3em 0;
            box-shadow: 0px 10px 30px #00000029;
            border-radius: 3em;
            position: relative;
            overflow: hidden;
            @include topBottomStrip;
        }
    }

    .score_board {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: space-between;
        font-size: 10px;

        @include breakpoint(scapeLgMobile) {
            flex-direction: column;
        }

        @include breakpoint(scapeMobile) {
            flex-direction: column;
        }

        @include breakpoint(midScreen) {
            font-size: 8px;
        }

        h1 {
            letter-spacing: 0px;
            color: $black;
            text-transform: uppercase;
            font-size: 2.6em;
            width: 20em;
            font-family: $black-font,sans-serif;
        }

        .board {
            width: 100%;
            background: $white;
            box-shadow: 0px 10px 30px #00000029;
            border-radius: 3em;
            margin: 2rem 0;
            overflow: hidden;
                position: relative;
                @include topBottomStrip;

        }

        &--school {
            margin-left: 2em;
            .player_components .players .exp{
                margin-top: 2px;
                img{
                    margin-top: -2px;
                }
            }
            .board {
                padding: 1rem;
            }

            .player_components {
                max-height: 68em;
                font-size: 8px;

                // adjust in School score board
                @include breakpoint(midScreen) {
                    font-size: 6px;
                }
            }
        }

        //adjust score baord component
        .board-tabs .content {
            min-width: 47em;
            position: relative;
            overflow: hidden;
            @include topBottomStrip;
            .tab-content {
                font-size: 8px;

                @include breakpoint(midScreen) {
                    font-size: 6px;
                }
            }

            // adjust in RAISING CHAMPS section
            .player_components {
                max-height: 68em;

                @include breakpoint(midScreen) {
                    font-size: 6px;
                }
            }
        }
    }
}


// .profile-page {
//     a{
//         margin-top: 10px;
//     }
//     button span{
//         margin-top: 10px;
//     }
// }