header {
    position: relative;
    background-color: $off-white;
    height: auto;
    width: 100vw;
    min-height: 80vh;
    padding: 2rem;
    margin-top: 7%;

    .header-text {
        position: relative;
        margin-top: 3rem;
        font-size: 7px;
        text-align: left;
        z-index: 30;
        width: 100%;

        @include breakpoint(mobile) {
            font-size: 7px;
        }

        @include breakpoint(xmobile) {
            font-size: 4px;
            margin-top: 6rem;
        }

        .primary-text {
            font-family: $ultra-font,sans-serif;
            font-size: 8em;
            text-transform: uppercase;
            letter-spacing: -1px;
            line-height: 40px;

            @include breakpoint(xmobile) {
                margin-top: -.9rem;
            }
        }

        .secondary-text {
            font-family: $thin-font,sans-serif;
            font-weight: bold;
            font-size: 3em;
            width: 23em;
            @media (max-width: 1024px) {
                width: 17em;
            }
            @media (max-width: 480px) {
                width: 14em;
            }
            a{
                display: inline-block;
                cursor: pointer;
            }
            @include breakpoint(xmobile) {
                letter-spacing: 0px;
                width: 15.5em;
                font-size: 4em;
            }



        }
    }


    .video-player {
        float: left;
        position: relative;
        margin-top: 5rem;
        z-index: 35;
        margin: 0 auto;
        border-radius: 20px;
        overflow: hidden;

        @include breakpoint(xmobile) {
            margin-top: 1rem;
        }

        &-vid {
            outline: none !important;
            width: 474px;
            height: 268px;

            @include breakpoint(smallScreen) {
                width: 360px;
                height: 130px;
            }

            @include breakpoint(mobile) {
                width: 270px;
            }

            @include breakpoint(xmobile) {
                width: 260px;
            }
        }

    }

    .players-block {
        position: relative;
        float: right;
        font-size: 10px;
        z-index: 20;
        right: -15%;
        margin-top: 5rem;
      

        @include breakpoint(smallScreen) {
            right: -41%;
            font-size: 10px;
            margin-top: 9rem;
        }
        @include breakpoint(midScreen) {
            font-size: 10px;
            margin-top: 9rem;
            right: -11%;
        }

        @include breakpoint(largeMobile) {
            right: -35%;
        }

        @include breakpoint(mobile) {
            font-size: 9px;
            right: -25%;
        }

        @include breakpoint(xmobile) {
            font-size: 7px;
            right: -30%;
        }
        @media screen and (min-width:768px) and (max-width:991px){
            position: relative;
            float: none;
            z-index: 20;
            right: 0;
            margin-top: 5rem;
            font-size: 15px;
        }
        @media (max-width:991px) {
            right: 0;
            float: none;
            display: flex;
        }
        &__content {
            width: 60em;
            height: 60em;

            img {
                object-fit: contain;
                width: 100%;
                height: 100%;
                z-index: 20;
                position: relative;
            }
        }

        // strips container
        &__strips-container {
            width: 36em;
            height: 38em;
            position: absolute;
            left: 50%;
            top: 34%;
            transform: translate(-50%, -50%);
            margin-left: 1em;

            //parent container
            &-parent {
                position: relative;
                width: 100%;
                height: 100%;

                .strip {
                    position: absolute;
                    bottom: 0;
                    width: 2.5em;
                    height: 25em;
                    background-color: $white;
                    box-shadow: 0px 3px 3px 3px #e6e6e6;

                    &:nth-child(even) {
                        background-color: $black;
                    }

                    &-one {}

                    &-two {
                        height: 37em;
                        left: 2.5em;
                    }

                    &-three {
                        height: 40em;
                        left: 7.5em;
                    }

                    &-four {
                        height: 35em;
                        left: 9.5em;
                    }

                    &-five {
                        height: 32em;
                        left: 14.5em;
                    }

                    &-six {
                        height: 40.2em;
                        left: 16.5em;
                    }

                    &-seven {
                        height: 42em;
                        left: 22em;
                    }

                    &-eight {
                        height: 38em;
                        left: 24.5em;
                    }

                    &-nine {
                        height: 34em;
                        left: 28.5em;
                    }

                    &-ten {
                        height: 40em;
                        left: 31.2em;
                    }
                }
            }
        }
    }
}
.line-height-1-2{
    line-height: 1.2;
}
.schoolMax{
    display: inline-block;
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.height-86{
    height: 86%;
}
#systemKids{
.playerName.schoolMax{
    max-width: initial!important;
    @media (max-width: 991px) {
        max-width: 300px!important;
    }@media (max-width: 767px) {
        max-width: 200px!important;
    }
}
    .schoolName{
        .schoolMax{
            max-width: initial!important;
            @media (max-width: 991px) {
                max-width: 300px!important;
            }@media (max-width: 767px) {
            max-width: 200px!important;
        }@media (max-width: 680px) {
            max-width: 150px!important;
        }
        }
    }
}