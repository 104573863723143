.forget-page{
    background-color: $off-white;

    .forget-password-page{
        width: 100%;
        height: 100%;
        background-color: $off-white;
        .wrapper{
            font-size: 10px;
            position: relative;
            margin-top: 15%;
            text-align: center;

        }
     
    }
    footer{
        margin: 0 !important;
        position: fixed;
        bottom: 0;
        width: 100%;
    }
    .players-block-component{
        font-size: 5px;
            top:65px;
    }
    .players-block-component__content {
        width: 50em;
        z-index: 20;
        position: relative;
        img{
            z-index: 20;
            position: relative;
            width: 45em;
            height: 67em;
            top: -80px;
                left: 2rem;
        }
    }
    button{
        @include breakpoint(smallScreen){
            margin: 0 auto !important;
        }
    }
    
    .jc_form .jc-btn{
        @include breakpoint(xmobile){
            width: 200px;
        }
    }
    // a{
    //     margin-top: 10px;
    // }
    .primary-title{
        @media (max-width: 767px) {
            margin-top: 40px;
        }
    }
    .jc_form .style-input input{
        width: 100%;
        padding: 15px !important;
        height: 46px;
        margin-bottom: 1rem;

    }
}





.login-page .jc_form .style-input input{
    font-family: sans-serif;
}

