.preloader{
    width:100%;
    height:100vh;
    background:#101010;
    overflow:hidden;
    position:fixed;
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);  
      z-index: 2222222;
    h1 {
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
    }
    
}

.pace {
    pointer-events: none;
    user-select: none;
    z-index: 2000;
    position: fixed;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 400px;
    border: 0px;
    height: 1px;
      z-index: 22222222222222;
      overflow: hidden;
    background:#1b1b1b;
  }
  
  .pace .pace-progress {
    box-sizing: border-box;
    transform: translate3d(0, 0, 0);
    max-width: 400px;
    position: fixed;
      z-index: 22222222222222;
      z-index: 2000;
    display: block;
    position: absolute;
    top: 0;
    right: 100%;
    height: 100%;
    width: 100%;
    background: grey;
  }
  
  .pace.pace-inactive {
    display: none;
  }