.game-page {
    margin-top:5%;
    .earned-badges {
        position: relative;
        width: 100%;
        padding: 2rem;
        font-size: 10px;
        margin: 2rem 0;

        @include breakpoint(minLargeScreen) {
            font-size: 13px;
        }

        @include breakpoint(scapeLgMobile) {
            font-size: 6.8px;
            margin: 2rem 0 0 0;
            padding: 0;
        }

        @include breakpoint(scapeMobile) {
            font-size: 6px;
            margin: 2rem 0 0 0;
            padding: 0;
        }

        h1 {
            color: $black;
            font-family: $black-font,sans-serif;
            letter-spacing: 0px;
            text-transform: uppercase;
            font-size: 3.6em;
        }

        .badges {
            position: relative;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            list-style: none;
            padding: 0;
            margin: 3rem 0;

            li {
                .item {
                     opacity: 0;

                    transition: all .5s ease-in-out;
                    width: 10em;
                    height: 10em;
                    background-color: $white;
                    border-radius: 3em;
                    box-shadow: 0px 10px 30px #00000043;
                    position: relative;
                }

                .empty-badge {
                    border: 2px dashed $black;
                    background: transparent;
                    box-shadow: none;
                }

                img {
                    width: 6em;
                    height: 5em;
                    position: absolute;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    top: 25%;
                }
            }




            //bootstrap overwrite badge component
            .badge {
                display: block;
                padding: .5rem;
                font-size: 100%;
                font-weight: 700;
                line-height: 1;
                text-align: center;
                white-space: nowrap;
                border-radius: .25rem;
                background: #DB1D1D;
                /* transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out; */
                border-radius: 50%;
                right: -7px;
                position: relative;
                top: -5px;
                float: right;
                border: 3.5px solid #F0F2F5;
                color: $white !important;
                // font-size: 1.5em;
            }
        }

        //end badges

    }

    .levels {
        position: relative;
        margin: 2rem 0;
        font-size: 10px;

        .game-swiper {
            overflow-x: scroll;
            font-size: 7px;
            @media (max-width: 767px) {
                font-size: 6px;
            }
        }
        @include breakpoint(scapeLgMobile) {
            font-size: 5px;
            margin: 0;
        }

        @include breakpoint(scapeMobile) {
            font-size: 4px;
            margin: 0;
        }

        .horizontal-line {
            position: absolute;
            left: 0;
            top: 50%;
            background-color: $black;
            height: 8px;
            width: 100%;
        }

        .level-item {
            position: relative;
            height: 40em !important;
            width: 27.7em !important;
            box-shadow: 0px 20px 30px #00000029;
            border-radius: 4em;
            background-color: $white;
            margin: 3rem 0;
            overflow: hidden;
            cursor: pointer;
            .locked-content {
                display: none;
            }

            .level-img {
                width: 100%;
                height: 100%;
            }

            .level-footer {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                margin: 0 auto;
                height: 10em;
                width: 100%;
                font-size: 1.6em;
                color: orange !important;
                text-align: center;
                display: flex;
                flex-direction: column;
                background: rgb(0,0,0);
                background: -moz-linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(181,181,181,0) 100%);
                background: -webkit-linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(181,181,181,0) 100%);
                background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(181,181,181,0) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#b5b5b5",GradientType=1);
                align-items: center;
                justify-content: center;

                .stars-rating {
                    .fa {
                        background: transparent;
                        color: transparent;
                        -webkit-text-stroke-width: 1px;
                        -webkit-text-stroke-color: orange;
                    }
                }

                .checked {
                    color: orange !important;

                }
            }
        }


        .locked {
            overflow: hidden;

            .locked-content {
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: 222;
                background: rgba(47, 47, 47, .8);
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0;
                padding: 0;
                flex-direction: column;
                text-align: center;
                color: $white;

                .lock-img {
                    width: 5em;
                }

                .coming-soon {
                    text-align: center;
                    letter-spacing: 0px;
                    color: #FFFFFF;
                    text-transform: uppercase;
                    font-family: $black-font,sans-serif;
                    font-size: 2.5em;
                }
            }

            .level-img {
                opacity: 1;
                filter: blur(3px) grayscale(100%) !important;
            }

            .level-footer {
                display: none;
            }
        }


    }


    .leaders {
        position: relative;
        padding: 2rem;

        .wrapper {
            position: relative;
            font-size: 10px;
            display: flex;
            justify-content: space-between;

            @include breakpoint(scapeLgMobile) {
                font-size: 7.2px;
            }

            @include breakpoint(scapeMobile) {
                font-size: 5px;
            }
        }
    }
    a{
        margin-top: 10px;
    }
    footer{
        position: fixed;
        width: 100%;
        bottom: 0;
    }

    /*.swiper-slide.level-item.swiper-slide-active:hover{
        transform: none;
    }*/
    .current-challenge{
        position: relative;
        top: 43px;
        z-index: 1;
        color: white;
    }
    .swiper-slide.level-item.show-card{
        text-align: center;
        transition: transform 1s, opacity 1s;
        .week-name{
            display: inline-block;
            padding-top: 4px;
        }
        .current-challenge{
            display: none;
        }
        img,.week-name{
            transition: transform 1s, opacity 1s;
        }
        &:hover{
        transform: scale(1.2);
            img,.week-name{
                opacity: .7;
            }
        }
        &:first-child{
            margin-left: 40px;
        }
        &:last-child{
            margin-right: 90px;
        }
        &.current-card{
            .current-challenge{
                display: inline-block;
                color: white;
            }
        }
    }
    .week-name{
        color: $gray-900;
        font-family: $med-font,sans-serif;
        font-size: 14px;
        padding-bottom: 8px;
        display: none;
    }
    .primary-title{
        font-size: 1.7rem;
        text-align: center;
    }
}




.players_leaderboard {

    //title
    &--title {
        position: relative;

        img {
            width: 4em;
            height: 4em;
        }

        span {
            font-size: 4em;
            top: 9px;
            position: relative;
            line-height: 23px;
            font-family: $black-font,sans-serif;
            text-transform: uppercase;
        }
    }

    //end title


    &--players {
        ul {
            padding: 0;
            list-style: none;
            margin: 2rem 0;

            .players {
                position: relative;
                display: flex;
                align-items: center;

                .playerImg {
                    width: 6em;
                    height: 6em;
                }

                .data {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;

                    .playerName {
                        text-align: left;
                        font-family: $black-font,sans-serif;
                        letter-spacing: 0px;
                        color: $black;
                        text-transform: capitalize;
                        font-size: 3em;
                    }

                    .exp {
                        text-align: left;
                        font-family: $med-font,sans-serif;
                        letter-spacing: 0px;
                        color: $black;
                        text-transform: capitalize;
                        font-size: 2.5em;
                        margin-top: 9px;

                        img {
                            width: 20px;
                            margin-top: -5px;
                            position: relative;
                            height: 20px;
                        }
                    }
                }
            }
        }
    }

}