.jc_form {
  position: relative;
 
  button {
    border: none !important;
    box-shadow: none !important;
    background: none !important;
  }

  .jc-btn {
    &:hover{
      span{
        color: black;
      }
    }
    width: 270px;
    span{font-family: sans-serif;}

  }

  .style-input {
    text-align: left;
    .style-input:focus {
      color: $black;
      border:2px solid $black;
    }


    input[type='password']:focus::placeholder{
      color: $white;
    }

    label{
      position: absolute;
      top: 26px;
      left: 28px;
      z-index: 3;
      transition: all 0.3s ease-in-out;
      color: $light-grey;
      font-size: 12px;
    }


    input {
      position: relative;
      box-sizing: border-box;;
      border: 2px solid transparent;
      border-radius: 15px;
      // padding: 1.2rem;
      padding: 30px 20px 11px 26px !important;
      height: auto;
      color: $black;
      // border: none;
      box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.13);
    font-family: sans-serif;
      font-weight: bolder;
      &:focus + label{
        top: 13px;
      }
      &:active + label{
        top: 13px;
      }
      
    }
    .form-control::placeholder{
      color: transparent;
    }
    .form-control:focus + .form-label,
    .form-control:not(:placeholder-shown) + .form-label
    {
      top: 13px;

    }

 

    input[type='checkbox'] {
      width: 21px;
      height: 21px;
      border-radius: 7px;
      background: #fff;
      border: 1px solid #dbd7d7;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      padding: 10px;

    }
    input[type="checkbox"]:focus {
      outline: 0;
    }

  }

  .form-check-label {
    margin: .5rem 1rem;
  }

  .forget-password {
    margin: .5rem 0;
    color: $black;
    text-decoration: underline;
  }

  .remember {
    color: $light-grey;
    font-family: $thin-font,sans-serif;
  }

  .label {
    position: relative;
    left: 2rem;
    // top: 0.3rem;
  }

  .left-1 {
    left: -1rem;
  }
}