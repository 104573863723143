.reset-page{
        background-color: $off-white;
        .reset-password-page{
        width: 100%;
        height: 100%;
        .wrapper{
            font-size: 10px;
            text-align: center;
            @include centerer;
          @media (max-width: 1200px)  {
            transform: none;
            position: inherit;

          }
        }
       
        @include breakpoint(largeScreen){
            .data-block{
                font-size: 10px;
            }
            .players-block-component {
                position: relative;
                float: right;
                font-size: 5px;
                z-index: 20;
                right: -46%;
                margin-top: 12rem;
            }
        }
        
      
        .players-block-component__content {
            width: 50em;
            z-index: 20;
            position: relative;
            img{
                z-index: 20;
                position: relative;
                width: 45em;
              left: 2rem;
                height: 67em;
            }
        }
    }
    footer{
        margin: 0 !important;
        position: fixed;
        bottom: 0;
        width: 100%;
    }
    .form-group{
        position: relative;
    }
    // a{
    //     margin-top: 10px;
    // }
  .primary-title{
    font-size: 4em!important;
    @media (max-width: 767px) {
      margin-top: 40px;
    }
  }
  .jc_form .style-input input{
    width: 100%;
    padding: 15px !important;
    height: 46px;
    margin-bottom: 1rem;

  }
  .reset-password-page{
    margin-top: 150px;
  }
  .jc_form .jc-btn{
    @media (max-width: 767px) {
      width: 190px;
    }
  }
}


.login-page .jc_form .style-input input{
  font-family: sans-serif;
}
