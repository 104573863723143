.video-page {
    footer{
        bottom: 0;
        width: 100%;
        position: fixed;

    }
    background: $off-white;
    .badge-popUp {
        position: fixed;
        width: 100vw;
        height: 100vh;
        z-index: 222;
        background-color: $off-white;
        top: 0;
        left: 0;
        display: none;

        .wrapper {
            position: absolute;
            width: 100%;
            z-index: 222;
            height: 100%;
            left: 0;

            .parent {
                position: absolute;
                top: 0;
                z-index: -1;
                width: 100%;
                height: 100%;
                background-color: $off-white;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                font-size: 8px;
                @include breakpoint(smallScreen){
                    font-size: 6px;
                }
                .title {
                    font-size: 8em;
                    text-transform: uppercase;
                    font-family: $black-font,sans-serif;
                    color: #5CCE72;
                }
                .sub-title {
                    font-size: 4em;
                    font-family: $med-font,sans-serif;
                    color: $light-grey;
                    text-align: center;
                }
                .shape {
                    img{
                        object-fit: contain;
                    }
                    height: 40em;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .span-container{
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        margin: 0 auto;
                        width: 85em;
                        height: 76em;
                        text-align: center;
                        transition: all 15s ease-in-out;
                        animation: rotate_span 15s ease-in infinite forwards;

                        @include keyframes(rotate_span){
                            0%{
                                transform: rotate(0deg);
                            }
                            50%{
                                transform: rotate(160deg) ;
                            }
                            80%{
                                transform: rotate(260deg) ;

                            }
                            100%{
                                transform: rotate(360deg) ;

                            }
                        }
                    }
                    img {
                        width: 70%;
                        height: 70%;
                        z-index: 22;
                    }

                    span {
                        width: 4em;
                        height: 80em;
                        background-color: rgb(255, 255, 255);
                        position: absolute;

                        &:nth-child(odd) {
                            background-color: rgb(0, 0, 0);
                        }

                        &:nth-child(2) {
                            background-color: rgb(0, 0, 0);
                            transform: rotate(-121deg);
                        }

                        &:nth-child(3) {
                            background-color: rgb(255, 255, 255);
                            transform: rotate(-221deg);
                        }

                        &:nth-child(4) {
                            background-color: rgb(0, 0, 0);
                            transform: rotate(-265deg);
                        }

                        &:nth-child(5) {
                            background-color: rgb(255, 255, 255);
                            transform: rotate(-284deg);
                        }

                        &:nth-child(6) {
                            transform: rotate(-328deg);
                        }

                        &:nth-child(6) {
                            background-color: rgb(255, 255, 255);
                            transform: rotate(-425deg);

                        }

                        &:nth-child(6) {
                            transform: rotate(210deg);


                        }
                    }
                }
            }
        }
    }
    .video-wrapper {
        position: relative;
        font-size: 10px;
        width: 100%;
        margin-top: 115px;
        margin-bottom: 60px;
        @media (max-width: 1024px) {
            margin-bottom: 100px;
        }
        @include breakpoint(minLargeScreen) {
            font-size: 13px;
        }

        @include breakpoint(ipadLandScape) {
            font-size: 9px;
        }

        .video-platform {
            display: flex;
            flex-direction: column;
            align-items: center;
            flex-grow: 1;

            .jc-btn {
                width: 245px;
                margin: 1rem auto;
                border: 1px solid $black;
            }
            .primary-title {
                color: $black;
                font-family: $black-font,sans-serif;
                letter-spacing: 0px;
                color: #000000;
                text-transform: uppercase;
                font-size: 2em;
                width: 100%;
                margin: 1rem 0;
                text-align: center;
            }

            &--video-container {
                position: relative;
                // width: 70em;
                // height: 49.7em;
                // width: 73em;
                // height: 41.7em;
                width: 61em;
                height: 32.7em;
                background-color: rgb(206, 206, 206);
                overflow: hidden;
                border-radius: 2em;

                .video-js {
                    width: 100%;
                    height: 100%;
                    .vjs-break-overlay-text{
                        @include breakpoint(scapeLgMobile) {
                            font-size: 10px;
                        }
                
                        @include breakpoint(scapeMobile) {
                            font-size: 10px;
                        }
                    }
                }

                video {
                    object-fit: cover !important;
                }

                .vjs-poster {
                    background-size: cover;
                }
            }

            &--loaded-videos {
                position: relative;
                width: 100%;
                max-width: 61em;
                margin: 1rem 0;
                @media (max-width: 767px) {
                    width: 200px;
                    margin: auto;
            }
            .loaded-video {
                margin-bottom: 30px;
                height: 200px;
                flex-basis: 31.5%;
                border: 3px dashed $light-black;
                height: 11.1em;
                border-radius: 2em;
                min-height: 125px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                @media (max-width: 767px) {
                    margin-bottom: 50px;
                }
                .plus-icon {
                    display: flex;
                    align-items: center;
                    font-size: 3em;
                    color: $light-black;
                }

                .upload-done {
                    video,img {
                        display: none;
                        width: 100%;
                    }
                }
            }

            .upload-hide {
                .loader {
                    display: none !important;
                }

                .cricle {
                    display: none !important;
                }
            }

            .upload-progress {
                position: relative;
                overflow: hidden;

                .plus-icon {
                    display: none;
                }

                .cricle {
                    display: none;
                }

                .bar {
                    position: absolute;
                    bottom: -3px;
                    left: 0px;
                    height: 50%;
                    width: 100%;
                    background: transparent linear-gradient(180deg, #FEDC15 0%, #D89521 100%) 0% 0% no-repeat padding-box;
                    border-radius: 0em 0em 2em 2em;

                }

                .loader {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    z-index: 3;
                    position: relative;

                    .percentage {
                        font-size: 1.5em;
                        display: block;
                    }

                    .uploading {
                        font-size: 1.5em;
                        display: block;
                    }

                }
            }

            .upload-complete {
                overflow: hidden;
                border: none;

                .plus-icon {
                    display: none !important;
                }

                video {
                    // display: block !important;
                }

                .loader {
                    display: none !important;
                }

                .upload-done {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    min-height: 125px;
                    position: relative;

                    .overlay {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        z-index: 25;
                        background: rgba(9, 9, 9, .6);
                        border-radius: 2em;
                    }

                    img {
                        z-index: 20;
                        position: relative;
                        border-radius: 2em;
                    }

                    .cricle {
                        width: 4em;
                        height: 4em;
                        background-color: $green;
                        z-index: 30;
                        position: absolute;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        i {
                            font-size: 2em;
                            color: black;
                        }
                    }
                }
            }

            .upload-in-progress {
                border: none;
                overflow: hidden;
                video{
                    display: block!important;
                    border-radius: 2em;
                }
                .cricle,.upload-inprogress,.plus-icon{
                    display: none;
                }
            }
            .show-uploaded-video{
                border: none;
                width: 400px;
                height: 400px;
                video{
                    display: block!important;
                    border-radius: 2em;
                    width: 400px!important;
                    outline: none;
                }
                .cricle,.upload-inprogress,.plus-icon{
                    display: none;
                }
            }
        }

        @media (max-width: 800px) {
            font-size: 9px;
        }
        @media (max-width: 650px) {
            font-size: 6px;
        }
        @media (max-width: 400px) {
            font-size: 4px;
            .primary-title{
                margin-top: 30px;
            }
        }
    }

    .timeline {
        top: 5em;
        position: relative;
        flex-grow: 4;

        &-wrapper {
            position: relative;
            width: 100%;
            height: 75%;
            padding: 2em;
            overflow: hidden;
            display: flex;
            .border-dotted {
                position: absolute;
                right: 0;
                left: 0;
                margin: 0 auto;
                z-index: 2;
                width: 2px;
                height: 71%;
                top: 17.5%;
                border: 3px dotted;
                border-image-source: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(181, 181, 181, 1) 45%, rgba(0, 0, 0, 0) 100%);
                border-image-slice: 1;
            }

            .alt {
                border: 5px dotted #f0f2f5;
            }

            .cards {
                position: relative;
                width: 100%;
                z-index: 22;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                .card {
                    width: 27.8em;
                    height: 10.1em;
                    border-radius: 2em;
                    background-color: $white;
                    margin: 2rem 0;
                    display: flex;
                    transform: scale(.5);
                    transition: all .3s ease-in-out;
                    justify-content: space-around;
                    align-items: center;
                    flex-direction: row;
                    filter: grayscale(1);

                    h4 {
                        font-size: 1.6em;
                        letter-spacing: 0px;
                        width: 41%;
                        color: $light-grey;
                        margin: 0;
                    }

                    .cloud-icon {
                        position: relative;

                        .fa-cloud {
                            font-size: 4em;
                            color: #BBDEFB;
                        }

                        .circle {
                            width: 3em;
                            height: 3em;
                            position: absolute;
                            background: $green;
                            border-radius: 50%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            bottom: -15px;
                            right: 11px;
                            border: 3px solid $white;
                            overflow: hidden;

                            i {
                                color: $white;
                                position: relative;
                                font-size: 1.6em;
                            }
                        }
                    }

                }

                .active {
                    border: 1px solid #5CCE72;
                    transform: scale(.8) !important;
                    filter: none !important;

                }
            }


        }
    }
}

.arrow_animation {
    transition: all 1s ease-in-out;
    animation: arrowTop 1.5s ease-in-out infinite forwards;
}
.landScapeMsg{
    display: none!important;
}
.upload-label{
    position: absolute;
    top: 135px;
    font-size: 12px;
    width: 175px;
    line-height: 1.2;
    text-align: center;
    @media (max-width: 1024px) {
        width: 145px;
    }
    @media (max-width: 767px) {
        width: 180px;
    }
}
    .upload-complete{
        .label{
            display: none;
        }
    }
    .upload-hide,.upload-in-progress{
        .click{
            display: none;
        }
    }
    .overflow-visible{
        overflow: visible!important;
    }
    .cover{
        object-fit: cover;
    }
    .max-height-108{
        max-height: 108px;
    }
    .upload-own-video{
        width: 50em;
        @media (max-width: 1024px) {
            width: 44em;
        }
        @media (max-width: 650px) {
            width: 30em;
        }
        @media (max-width: 400px) {
            width: 20em;
        }
    }
}



@include keyframes(arrowTop) {
0% {
    transform: translateY(25px);
    opacity: 0;
}

100% {
    transform: translateY(-25px);
    opacity: 1;
}


}
.pointer{
cursor: pointer;
}
.max-width-initial{
max-width: initial!important;
}
.vjs-big-play-button{
top: 50%!important;
left: 50%!important;
transform: translate(-50%,-50%);
}