.jc-btn {
    position: relative;
    width: 180px;
    height: 55px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px solid $black;
    overflow: hidden;
    @include breakpoint(smallScreen){
        width: 100px;
        height: 40px;
    }
    @include breakpoint(largeMobile){
        width: 110px;
        height: 45px;
    }
    a {
        color: $white !important;
        z-index: 22;
        position: relative;
        text-transform: uppercase;
        font-size: 14px;
        // margin-top: .5rem !important;
        position: relative;
        transition: all .4s ease-in-out;
        text-decoration: none !important;
        // &::before {
        //     position: absolute;
        //     transition: all .4s ease-in-out;
        //     width: 0%;
        //     height: 2px;
        //     background-color: $black;
        //     content: '';
        //     left: 0;
        //     bottom: 0;
        // }
        @include breakpoint(largeMobile){
            font-size: 12px;;
        }
            
      
    }
    // &:hover a::before {
    //     width: 100%;
    // }
    .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        top: 0;
        @include primary-gradient;
        transition: all .4s ease-in-out;
    }

    &:hover a {
        color: $black !important;
    }

    &:hover .overlay {
        height: 0;
    }

    &:hover {
        border: 1px solid $black;
    }
}
.disabled{
    pointer-events: none;
    a{
        pointer-events: none;
    }
    .overlay{
        background: gray !important;

    }
    &:hover .overlay {
        height: 100% !important;
    }
}
