.userProfile-page {
    background: $off-white;

    .container {
        @include breakpoint(scapeLgMobile) {
            max-width: 100% !important;
        }

        @include breakpoint(scapeMobile) {
            max-width: 100% !important;

        }
    }

    .wrapper {
        position: relative;
        font-size: 10px;
        margin-top: 15%;

        @include breakpoint(scapeLgMobile) {
            font-size: 7px;
            margin-top: 10%;
        }

        @include breakpoint(scapeMobile) {
            font-size: 5px;
            margin-top: 10%;
        }

        .header {
            position: relative;
            width: 100%;
            height: auto;
            padding: 3rem ;

            @include breakpoint(scapeLgMobile) {
                padding: 3rem 2rem;
            }

            @include breakpoint(scapeMobile) {
                padding: 3rem 2rem;

            }

            .jc-btn {
                border: 1px solid $red;

                .overlay {
                    background: transparent linear-gradient(287deg, #DB1D1D 0%, #FF0000 100%) 0% 0% no-repeat padding-box !important;
                }

                a {
                    font-size: 10px;
                }
            }

            .img-container {
                width: 9.1em;
                height: 9.1em;
                position: relative;

                &--owned-badges {
                    position: absolute;
                    width: 3.9em;
                    height: 3.9em;
                    background: white;
                    bottom: 0;
                    right: 0;
                    border-radius: 1em;
                    padding: .7em;
                    box-shadow: 0px 10px 30px #00000043;

                    .season-badge {
                        width: 2.5em;
                        height: 2.5em;
                    }
                }

                img {
                    width: 9.1em;
                    height: 9.1em;
                }
            }

            .data {
                margin-left: 2em;

                .welcome {
                    text-align: left;
                    font-family: $bold-font,sans-serif;
                    letter-spacing: -1px;
                    color: $red;
                    text-transform: uppercase;
                    font-size: 2em;
                }

                .primary-title {
                    color: $black;
                    font-family: $black-font,sans-serif;
                    letter-spacing: 0px;
                    color: #000000;
                    text-transform: uppercase;
                    font-size: 4em;
                }

                .exp {
                    color: $light-grey;
                    font-size: 1.5em;
                }
            }
        }

        .stats {
            width: 100%;
            position: relative;

            @include breakpoint(scapeLgMobile) {
                justify-content: center !important;
                align-items: center !important;
                flex-direction: column !important;
            }

            @include breakpoint(scapeMobile) {
                justify-content: center !important;
                align-items: center !important;
                flex-direction: column !important;
            }

            .prize {

                @include breakpoint(scapeLgMobile) {
                    flex-direction: row !important;
                    font-size: 7px;
                    display: flex;
                    justify-content: space-around;
                    width: 89%;
                }

                @include breakpoint(scapeMobile) {
                    flex-direction: column !important;
                    font-size: 11px;
                    /* justify-content: space-between; */
                    display: flex;
                    align-items: center;
                    width: 100%;
                }
                @include breakpoint(scapeMidMobile) {
                    font-size: 6px;
                }
            }

            .primary-title {
                color: $black;
                font-family: $black-font,sans-serif;
                letter-spacing: 0px;
                color: #000000;
                text-transform: uppercase;
                font-size: 3em;
            }

            .leaderboard {
                position: relative;
                width: 50em;
                max-width: 55em;
                min-width: 46em;
                padding-right: 15px ;
                @include breakpoint(scapeLgMobile) {
                    width: 85%;
                    max-width: 90%;
                }

                @include breakpoint(scapeMobile) {
                    width: 75%;
                    max-width: 80%;
                }

                .board {
                    position: relative;
                    border-radius: 2em;
                    ul.player_components:last-child{
                        padding-bottom: 40px;
                    }
                    // padding: 2em;
                    .player_components .players .data .playerName {
                        font-size: 2em;
                    }

                    .player_components {
                        max-height: 104em;
                        position: relative;

                    }

                    .nav {
                        margin: 0 !important;
                        padding: 0;

                        .nav-link {
                            background-color: #f2f3f8;
                            border-radius: 0;
                            color: $light-grey;
                            text-transform: uppercase;
                            font-family: $black-font,sans-serif;
                            box-shadow: 0px -10px 10px #82828243;
                            padding: 1rem;
                            font-size: 11px;
                            height: 100%;
                        }

                        .nav-item {
                            width: 32.93%;
                            margin: 0 .1em;
                            display: inline-block;
                            color: $light-grey;
                            text-align: center;

                            &:nth-child(1) {
                                a {
                                    border-radius: 2em 0em 0em 0em;
                                }
                            }

                            &:nth-child(3) {
                                a {
                                    border-radius: 0em 2em 0em 0em;
                                }
                            }

                        }

                        .active {
                            background-color: $white;
                            color: $light-grey;
                        }
                    }

                    .tab-content {
                        background-color: white;
                        box-shadow: 0px 10px 30px #00000043;
                        border-radius: 0em 0em 2em 2em;
                        padding:0em 2em;
                        overflow: hidden;
                        font-size: 8px;
                        position: relative;
                        &::before {
                            content: '';
                            position: absolute;
                            width: 100%;
                            height: 50px;
                            top: 0;
                            z-index: -1;
                            left: 0;
                            background: rgb(255, 255, 255);
                            background: -moz-linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
                            background: -webkit-linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
                            background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
                            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#ffffff", GradientType=1);
                        }
                        &::after {
                            content: '';
                            position: absolute;
                            width: 100%;
                            height: 50px;
                            bottom: 0;
                            z-index: 222;
                            left: 0;
                            background: rgb(255, 255, 255);
                            background: -moz-linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
                            background: -webkit-linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
                            background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
                            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#ffffff", GradientType=1);
                        }
                        @include breakpoint(scapeLgMobile) {
                            font-size: 6px;

                        }

                        @include breakpoint(scapeMobile) {
                            font-size: 6px;
                        }
                    }
                }

                .primary-title {
                    font-size: 3em;
                    color: $black;
                    font-family: $black-font,sans-serif;
                    letter-spacing: 0px;
                    color: #000000;
                    text-transform: uppercase;
                    margin: 1rem 0;
                }
            }

            .achievements {
                position: relative;
                margin-bottom: 4rem;
                padding-right: 15px;

                .primary-title {
                    font-size: 3em;
                    color: $black;
                    font-family: $black-font,sans-serif;
                    letter-spacing: 0px;
                    color: #000000;
                    text-transform: uppercase;
                    margin: 1rem 0;
                }

                .board {
                    position: relative;
                    border-radius: 2em;
                    background-color: $white;
                    width: 40em;
                    max-width: 55em;
                    height: 36.5em;
                    padding: 1rem;
                    overflow-y: scroll;
                    box-shadow: 0px 10px 30px #82828243;

                }

                &-logos {
                    list-style: none;
                    position: relative;
                    width: 100%;
                    padding: 0;
                    display: grid;
                    grid-template-columns: auto auto auto;
                    @include breakpoint(scapeMidMobile) {
                        grid-template-columns: auto auto ;
                    }
                    li {
                        margin: 1rem;

                        a {
                            display: inline-block;

                            img {
                                width: 8em;
                                @include breakpoint(scapeMidMobile) {
                                width: 10em;
                                }
                            }
                        }
                    }
                }
            }

            .rewards {
                position: relative;

                .primary-title {
                    font-size: 3em;
                    color: $black;
                    font-family: $black-font,sans-serif;
                    letter-spacing: 0px;
                    color: #000000;
                    text-transform: uppercase;
                    margin: 1rem 0;
                }

                .board {
                    position: relative;
                    border-radius: 2em;
                    background-color: $white;
                    width: 40em;
                    min-width: 260px;
                    height:max-content;
                    padding: 2em;
                    box-shadow: 0px 10px 30px #82828243;

                    .tier {
                        .row {
                            margin-bottom: 1rem;
                        }

                        .primary-title {
                            font-family: $bold-font,sans-serif;
                            text-transform: capitalize;
                            margin-bottom: 1.3em;
                        }

                        .item {
                            .img-box {
                                width: 10em;
                                height: 10em;

                                img {
                                    width: 100%;
                                }
                            }

                            text-align: center;

                            &-title {
                                font-size: 1.4em;
                                font-weight: bolder;
                                margin: .4em 0;
                                font-family: $thin-font,sans-serif;
                            }

                            .tier-number {
                                font-size: 1.3em;
                                color: $light-grey;
                            }
                        }
                    }
                }
            }

        }
    }
    // a{
    //     margin-top: 10px;
    // }
    .badge{
        display: block;
        padding: .5rem;
        font-size: 100%;
        font-weight: 700;
        line-height: 1;
        text-align: center;
        white-space: nowrap;
        background: #DB1D1D;
        border-radius: 50%;
        right: 5px;
        position: relative;
        top: -20px;
        float: right;
        border: 3.5px solid #F0F2F5;
        color: #fff !important;
        width: 30px;
        height: 30px;
    }
}