@import "variables";
@include font-face($thin-font,gothamlight);
@include font-face($med-font,gothammedium);
@include font-face($bold-font,gothambold);
// @include font-face($black-font,gothamblack);
@font-face {
    font-family:$black-font;
    src:url("../fonts/gotham-Black.otf") format("opentype"),
    url("../fonts/gotham-Black.otf") format("opentype"),
    url("../fonts/gotham-Black.svg?#webfont") format("svg");
  }

  @font-face {
    font-family:$ultra-font;
    src:url("../fonts/gotham-ultra.otf") format("opentype"),
    url("../fonts/gotham-ultra.otf") format("opentype"),
    url("../fonts/gotham-ultra.svg?#webfont") format("svg");
  }