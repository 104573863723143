footer{
  @media  (max-width: 767px){
    display: none!important;
  }
    background-color: $black;
    margin-top: 6rem;
    z-index: 22222;
    position: relative;
    .wrapper{
        padding: 1rem 0 0rem 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $white;
        width: 100%;
        @include breakpoint(smallScreen){
            flex-direction: column;
        }
        .social-icons{
            position: relative;
            width: 10rem;
            ul{
                list-style: none;
                display: flex;
                justify-content: space-around;
                align-items: center;
                li{
                    margin: 0 .5rem;
                  a{
                      i{
                        font-size: 1.2rem;
                        background: white;
                        border-radius: 30px;
                        padding: .3rem;
                        color: black;
                  
                      }
                  }
                  &:nth-child(1) a i{
                    padding: .3rem .5rem;
                }
                }
            }
        }
        p{
            font-size: .8rem;
        }
    }
    
}